import React from "react";

import "../components/style.scss";
import Navbar from "../components/navbar";

const inputDefaultStyle = "w-64 h-8 my-2 px-2 rounded-lg text-gray-800 outline-none";
const invalidStyle = "border-2 border-red-500";
const validatedStyle = "border-2 border-teal-500";
const submitInvalid = "bg-gray-200 text-gray-800 my-2 px-2 rounded-lg ";
const submitValid = "bg-teal-300 text-white shadow-lg";

interface IProps {
    queryParams: { [key: string]: any };
}

let initialState = {
    email: "",
    password: "",
    password2: "",
    failure: false,
    success: false,
    errorMsg: ""
};

type State = Readonly<typeof initialState>

class Reset extends React.Component<IProps, State> {
    state = initialState;
    props: IProps;

    constructor(props: IProps) {
        super(props);
        if (this.props.queryParams['resetToken'] !== undefined) {

        }
    }

    render() {
        return (
            <div className="w-full">
                <Navbar callbackFromParent={() => console.log('hi') }/>
                <div className="bg-gray-200 w-full">
                    <form
                        className="flex flex-col justify-center items-center bg-white"
                        onSubmit={this.handleSubmit}
                    >
                        <input
                            type="text"
                            name="email"
                            placeholder="Email"
                            id="email"
                            className={`${inputDefaultStyle} ${this.validateEmail() ? validatedStyle : invalidStyle}`} onChange={this.handleEmailChange}
                        />
                        <input
                            type="text"
                            name="password"
                            placeholder="New Password"
                            id="password"
                            className={`${inputDefaultStyle} ${this.validatePassword() ? validatedStyle : invalidStyle}`} onChange={this.handlePasswordChange}
                        />
                        <input
                            type="text"
                            name="password-confirm"
                            placeholder="Confirm Password"
                            className={`${inputDefaultStyle} ${this.validatePassword2() ? validatedStyle : invalidStyle}`} onChange={this.handlePassword2Change}
                        />
                        <button type="submit" className={`${inputDefaultStyle} ${this.validatePassword2() ? submitValid : submitInvalid}`} onChange={this.handlePassword2Change}>Submit</button>
                        <div className={`${this.state.failure ? '' : 'hidden'} bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-64 my-5" role="alert`}>
                            <strong className="font-bold">Error processing request! <br/>{this.state.errorMsg} </strong>
                            <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => this.setState({failure: false})}>
                                <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" ><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                            </span>
                        </div>
                        <div className={`${this.state.success ? '' : 'hidden'} bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md`} role="alert">
                            <div className="flex items-center justify-center">
                                <div className="py-1"><svg className="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                                <div className="font-bold">Your password has been successfully changed</div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
    validateEmail = () => {
        if (this.state.email.indexOf('@') > 1) {
            return true;
        } else { return false; }
    }
    validatePassword = () => {
        if (this.state.password.length > 6) {
            return true;
        } else { return false; }
    }
    validatePassword2 = () => {
        if (this.validatePassword()) {
            if (this.state.password === this.state.password2) {
                return true;
            } else { return false; }
        } else { return false; }
    }
    handlePasswordChange = event => {
        this.setState({ password: event.target.value });
    };
    handlePassword2Change = event => {
        this.setState({ password2: event.target.value });
    };
    handleEmailChange = event => {
        this.setState({ email: event.target.value });
    }
    handleSubmit = event => {
        event.preventDefault();
        fetch('https://jir1btvwpk.execute-api.us-east-1.amazonaws.com/prod/resetPasswordVerify/', {
            method: 'POST',
            body: JSON.stringify({
                resetCode: this.props.queryParams.resetToken,
                email: this.state.email,
                password: this.state.password
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then((response) => {
                console.log(JSON.stringify(response));
                if (response.status === 200) {
                    this.setState({success: true});
                } else {
                    this.setState({failure: true, errorMsg: response.body['errorMsg']})
                }
            })
            .catch((error) => {
                this.setState({failure: true, });
            });
    }
}


export default Reset;
